html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: SharpSansNo1-Medium;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  background-color: #f6f8fb;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.geo-db {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.geo-db__loader {
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.geo-db__loader-title {
  text-align: center;
  margin-top: .5rem;
  font-size: 1.2rem;
}

.geo-db__loader.show {
  pointer-events: auto;
  opacity: 1;
}

.geo-db__loader img {
  margin-bottom: 3rem;
}

svg.tea {
  --secondary: #33406f;
  transform: translateY(-500%);
}

.geo-db__loader.show svg.tea {
  transform: translateY(0);
}

svg.tea #teabag {
  transform-origin: top;
  animation: swing 2s infinite;
  transform: rotate(3deg);
}

svg.tea #steamL {
  stroke-dasharray: 13;
  stroke-dashoffset: 13px;
  animation: steamLarge 2s infinite;
}

svg.tea #steamR {
  stroke-dasharray: 9;
  stroke-dashoffset: 9px;
  animation: steamSmall 2s infinite;
}

@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13px;
    opacity: .6;
  }

  100% {
    stroke-dashoffset: 39px;
    opacity: 0;
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9px;
    opacity: .6;
  }

  80% {
    stroke-dashoffset: 27px;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27px;
    opacity: 0;
  }
}

.geo-db__form-wrapper {
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  border-radius: 2rem;
  flex-direction: column;
  margin: 2rem;
  padding: 2rem;
  display: flex;
  box-shadow: 0 .5rem 1rem #00000026;
}

.geo-db__additional-info {
  justify-content: space-around;
  padding: 1.5rem;
  display: flex;
}

.geo-db__additional-info-box {
  text-align: center;
  width: 100%;
  max-width: 180px;
  border: 2px solid #f6f8fb;
  border-radius: 2rem;
  padding: 1.5rem;
}

.geo-db__additional-info-box img {
  margin-bottom: 15px;
}

.geo-db__additional-info-box h3 {
  margin-bottom: 15px;
  font-size: .8em;
}

.geo-db__additional-info-box h2 {
  cursor: help;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.8em;
  overflow: hidden;
}

.geo-db__form-info {
  text-align: center;
  color: #0048ff;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 2px solid #0048ff;
  border-radius: 2rem;
  grid-column: 1 / -1;
  margin-top: 40px;
  padding: 1.5rem;
  display: none;
  overflow: hidden;
}

.geo-db__form-info.error {
  color: #ff607b;
  border-color: #ff607b;
  font-family: SharpSansNo1-Semibold;
  font-size: 1.3em;
}

.geo-db__form {
  width: 100%;
  max-width: 500px;
  grid-column: 1 / 2;
  margin: 10px auto;
  padding: 1.5rem;
}

.geo-db__form-group-input .form__group {
  width: 100%;
  margin-bottom: 30px;
  padding: 15px 0 0;
  position: relative;
}

.geo-db__form-group-input .form__field {
  width: 100%;
  color: #000;
  background: none;
  border: 0;
  border-bottom: 2px solid #000;
  outline: 0;
  padding: 7px 0;
  font-family: inherit;
  font-size: 1.3rem;
  transition: border-color .2s;
}

.geo-db__form-group-input .form__field::placeholder {
  color: #0000;
}

.geo-db__form-group-input .form__field:placeholder-shown ~ .form__label {
  cursor: text;
  font-size: 1.3rem;
  top: 20px;
}

.geo-db__form-group-input .form__label {
  color: #000;
  font-size: 1rem;
  transition: all .2s;
  display: block;
  position: absolute;
  top: 0;
}

.geo-db__form-group-input .form__field:focus {
  border-width: 3px;
  border-image: linear-gradient(to right, #0048ff, #d3e9ef) 1;
  padding-bottom: 6px;
  font-weight: 700;
}

.geo-db__form-group-input .form__field:focus ~ .form__label {
  color: #0048ff;
  font-size: 1rem;
  font-weight: 700;
  transition: all .2s;
  display: block;
  position: absolute;
  top: 0;
}

.geo-db__form-group-input .form__field:required, .geo-db__form-group-input .form__field:invalid {
  box-shadow: none;
}

.geo-db__form-submit {
  cursor: pointer;
  background: none;
  border: 1px solid #fff;
  transition: all 1s;
}

.geo-db__form-submit-wrapper {
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.geo-db__form-submit-fancy .geo-db__form-submit-text {
  font-family: SharpSansNo1-Semibold;
  font-size: 1.3em;
}

.geo-db__form-submit-fancy {
  color: #fff;
  min-width: 200px;
  width: auto;
  height: 56px;
  cursor: pointer;
  background-color: #000;
  border: none;
  border-radius: 28px 0;
  outline: none;
  box-shadow: 0 .5rem 1rem #00000026;
}

.page {
  height: 100vh;
  max-width: 960px;
  flex-direction: column;
  margin: auto;
  display: flex;
}

.header {
  height: 130px;
  border-bottom: 4px solid #fff;
  justify-content: space-between;
  margin: 0 30px;
  display: flex;
}

.header div {
  align-items: center;
  display: flex;
}

.header img {
  width: 200px;
}

.header h2 {
  font-family: SharpSansNo1-Semibold;
  font-size: 1.5em;
}

.content {
  flex-direction: column;
  flex: auto;
  margin: 150px 30px 0;
  display: flex;
}

.footer {
  height: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  display: flex;
}

.geo-db__logo img {
  height: inherit;
}

a {
  color: #0048ff;
  font-weight: bold;
}

.geo-db__bridge-warning {
  text-align: center;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .geo-db__bridge-warning {
    font-size: 1.4rem;
    line-height: 1.8;
  }
}

@font-face {
  font-family: SharpSansNo1-Medium;
  src: url("SharpSansNo1-Medium.54b5211f.woff2") format("woff2"), url("SharpSansNo1-Medium.46455cc6.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SharpSansNo1-Semibold;
  src: url("SharpSansNo1-Semibold.0cec845a.woff2") format("woff2"), url("SharpSansNo1-Semibold.68a7b754.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SharpSansNo1-Bold;
  src: url("SharpSansNo1-Bold.fa3a3619.woff2") format("woff2"), url("SharpSansNo1-Bold.f1437c9a.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*# sourceMappingURL=index.6a7f20e5.css.map */
