/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: SharpSansNo1-Medium;
  /* font: inherit; */
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  background-color: #f6f8fb;
  /* background-color: #271C22; */
  /* display: flex;
    flex-direction: column; */
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.geo-db {
  overflow: hidden auto;
  display: flex;
  /* flex-direction: column; */
  /* flex: 1 1 auto; */
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* height: 100vh; */
}

.geo-db__loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  background: #ffffff;
}

.geo-db__loader-title {
  /* text-transform: uppercase; */
  /* font-family: SharpSansNo1-Semibold; */
  font-size: 1.2rem;
  /* font-size: 0.8rem; */
  margin-top: 0.5rem;
  text-align: center;
}

.geo-db__loader.show {
  pointer-events: auto;
  opacity: 1;
}

.geo-db__loader img {
  margin-bottom: 3rem;
}

svg.tea {
  --secondary: #33406f;
  transform: translateY(-500%);
}

.geo-db__loader.show svg.tea {
  transform: translateY(0);
}

svg.tea #teabag {
  transform-origin: top center;
  transform: rotate(3deg);
  animation: swing 2s infinite;
}

svg.tea #steamL {
  stroke-dasharray: 13;
  stroke-dashoffset: 13;
  animation: steamLarge 2s infinite;
}

svg.tea #steamR {
  stroke-dasharray: 9;
  stroke-dashoffset: 9;
  animation: steamSmall 2s infinite;
}

@-moz-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-webkit-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-o-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-moz-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-webkit-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-o-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-moz-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-webkit-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-o-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

.geo-db__form-wrapper {
  /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem; */

  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 2rem;
  padding: 2rem;
  margin: 2rem;
  width: 100%;
  max-width: 700px;
  /* margin: 0 auto; */
  box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 15%);
}

.geo-db__additional-info {
  /* max-width: 30rem; */

  /* grid-column: 2 / 3; */
  display: flex;
  justify-content: space-around;
  /* border: 0.1rem solid rgba(0, 0, 0, 0.5); */
  padding: 1.5rem;
  /* margin-bottom: 20px; */
}

.geo-db__additional-info-box {
  text-align: center;
  width: 100%;
  max-width: 180px;
  border: 2px solid #f6f8fb;
  border-radius: 2rem;
  padding: 1.5rem;
}

.geo-db__additional-info-box img {
  margin-bottom: 15px;
}

.geo-db__additional-info-box h3 {
  font-size: 0.8em;
  /* font-family: SharpSansNo1-Semibold; */
  margin-bottom: 15px;
}

.geo-db__additional-info-box h2 {
  font-size: 1.8em;

  cursor: help;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.geo-db__form-info {
  grid-column: 1 / -1;
  text-align: center;
  display: none;
  border: 2px solid #0048ff;
  color: #0048ff;
  /* border: 2px solid #f6f8fb; */
  border-radius: 2rem;
  /* border: 0.1rem solid rgba(0, 0, 0, 0.5); */
  padding: 1.5rem;
  margin-top: 40px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.geo-db__form-info.error {
  border-color: #ff607b;
  color: #ff607b;

  font-size: 1.3em;
  /* font-weight: bolder; */
  font-family: SharpSansNo1-Semibold;
}

.geo-db__form {
  /* max-width: 30rem; */

  grid-column: 1 / 2;
  /* border: 0.1rem solid rgba(0, 0, 0, 0.5); */
  padding: 1.5rem;
  margin: 10px auto;
  width: 100%;
  max-width: 500px;
}

.geo-db__form-group-input .form__group {
  position: relative;
  padding: 15px 0 0;
  width: 100%;
  margin-bottom: 30px;
}

.geo-db__form-group-input .form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #000000;
  outline: 0;
  font-size: 1.3rem;
  color: #000000;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.geo-db__form-group-input .form__field::placeholder {
  color: transparent;
}

.geo-db__form-group-input .form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.geo-db__form-group-input .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #000000;
}

.geo-db__form-group-input .form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #0048ff, #d3e9ef);
  border-image-slice: 1;
}

.geo-db__form-group-input .form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #0048ff;
  font-weight: 700;
}

/* reset input */
.geo-db__form-group-input .form__field:required,
.geo-db__form-group-input .form__field:invalid {
  box-shadow: none;
}

.geo-db__form-submit {
  border: solid rgba(255, 255, 255, 1) 1px;
  background: transparent;
  cursor: pointer;
  transition: all 1s;
}

.geo-db__form-submit-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.geo-db__form-submit-fancy .geo-db__form-submit-text {
  font-size: 1.3em;
  font-family: SharpSansNo1-Semibold;
}

.geo-db__form-submit-fancy {
  border: none;
  color: #fff;
  background-color: #000;
  min-width: 200px;
  width: auto;
  height: 56px;
  border-radius: 28px 0px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 15%);
}

/* 
GeoDB Theme
*/

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 960px;
  margin: auto;
}

.header {
  /* background-color: #0048ff;
  color: #fff; */
  height: 130px;
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #fff;
  margin: 0 30px;
}

.header div {
  display: flex;
  align-items: center;
}

.header img {
  width: 200px;
}

.header h2 {
  font-size: 1.5em;
  /* font-weight: bolder; */
  font-family: SharpSansNo1-Semibold;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  /* justify-content: center; */
  margin: 150px 30px 0px 30px;
}

/* .header__red-line {
  background-color: #ff607b;
  height: 16px;
} */

.footer {
  /* background-color: #000;
  color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 0 30px;
}

/* .geo-db__logo {
  margin-bottom: 3rem;
  max-height: 10rem;
} */

.geo-db__logo img {
  height: inherit;
}

a {
  color: #0048ff;
  font-weight: bold;
  /* color: inherit; */
  /* text-decoration: none; */
}

.geo-db__bridge-warning {
  text-align: center;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .geo-db__bridge-warning {
    font-size: 1.4rem;
    line-height: 1.8;
  }
}

@font-face {
  font-family: 'SharpSansNo1-Medium';
  src: url('./fonts/SharpSansNo1-Medium.woff2') format('woff2'),
    url('./fonts/SharpSansNo1-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpSansNo1-Semibold';
  src: url('./fonts/SharpSansNo1-Semibold.woff2') format('woff2'),
    url('./fonts/SharpSansNo1-Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpSansNo1-Bold';
  src: url('./fonts/SharpSansNo1-Bold.woff2') format('woff2'),
    url('./fonts/SharpSansNo1-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
